import React from "react";
import { connect } from "react-redux";
import { IProductUUID } from "../../../models/nominals";
import { TStateMapper } from "../../reducers.interfaces";
import { getAllFormSubmittedForParentUUID } from "../selectors";

interface IOwnProps {
    parentUUID: IProductUUID;
}

interface IReduxProps {
    isOpen: boolean;
}

interface IProps extends IOwnProps, IReduxProps {}

interface IState {}

class WriteReviewMultiThankYouComponent extends React.PureComponent<
    IProps,
    IState
> {
    render() {
        if (!this.props.isOpen) {
            return null;
        }
        return (
            <div className="review-thank-you">
                <h2 className="review-thank-you__title">
                    {gettext("Thank You for Writing a Review")}
                </h2>
                <p>
                    {gettext(
                        "We appreciate your feedback and comments, and always enjoy hearing from you.",
                    )}
                </p>
                <p>
                    {gettext("Return to the ")}
                    <a
                        title={gettext("Home")}
                        href="/"
                        className="review-thank-you__home"
                        target="_self"
                    >
                        {gettext("homepage")}
                    </a>
                    <span>.</span>
                </p>
            </div>
        );
    }
}

const mapStateToProps: TStateMapper<"reviews", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    const allFormsSubmitted = getAllFormSubmittedForParentUUID(
        rootState.reviews,
        ownProps,
    );
    return {
        ...ownProps,
        isOpen: allFormsSubmitted,
    };
};

export const WriteReviewMultiThankYou = connect(mapStateToProps)(
    WriteReviewMultiThankYouComponent,
);
