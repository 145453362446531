import React from "react";
import { Form } from "tsi-common-react/src/forms/Form";
import SVG from "react-inlinesvg";
import { FormSubmit } from "tsi-common-react/src/forms/FormSubmit";
import { FormInput } from "tsi-common-react/src/forms";
import { signupForEmailOffers } from "tsi-common-react/src/api/user";
import { getPageSetting } from "tsi-common-react/src/utils/settings";
import logoSO from "../../svg/so-logo.svg";

interface IFormState {
    isSubmitting: boolean;
    formSubmitted: boolean;
    emailValue: string;
    errorMessage: string | null;
}

export const EmailCapture = () => {
    const [formState, setFormState] = React.useState<IFormState>({
        isSubmitting: false,
        formSubmitted: false,
        emailValue: "",
        errorMessage: null,
    });

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setFormState((prevState) => ({
            ...prevState,
            isSubmitting: true,
        }));
        try {
            await signupForEmailOffers(formState.emailValue);
            setFormState((prevState) => ({
                ...prevState,
                isSubmitting: false,
                formSubmitted: true,
                errorMessage: null,
            }));
        } catch (error) {
            if (error.response) {
                if (
                    error.response.status === 400 &&
                    error.response.body &&
                    error.response.body.email
                ) {
                    console.error(error);
                    setFormState((prevState) => ({
                        ...prevState,
                        isSubmitting: false,
                        errorMessage:
                            "Please enter a valid email address (Ex: johndoe@domain.com).",
                    }));
                } else if (error.response.status === 429) {
                    console.error(error);
                    setFormState((prevState) => ({
                        ...prevState,
                        isSubmitting: false,
                        errorMessage: "Please try again later.",
                    }));
                } else {
                    console.error(error);
                    setFormState((prevState) => ({
                        ...prevState,
                        isSubmitting: false,
                    }));
                }
            }
        }
    };

    const handleEmailChange = (event: React.FormEvent<HTMLInputElement>) => {
        const email = event.currentTarget.value;
        setFormState((prevState) => ({
            ...prevState,
            emailValue: email,
        }));
    };

    const { isSubmitting, formSubmitted, emailValue } = formState;

    const appSlugName = getPageSetting("app-slug");
    const isOutletSite = appSlugName === "sleepoutfittersoutlet";

    return (
        <section className="email-capture">
            <h2>
                {isOutletSite ? (
                    <div>
                        <SVG
                            aria-hidden="true"
                            src={logoSO}
                            title={gettext("SleepOutfitters")}
                        />
                        <span>{gettext("OUTLET")}</span>
                    </div>
                ) : (
                    <>
                        {gettext("Smarter")}
                        <br />
                        {gettext("Sleep Solutions")}
                    </>
                )}
            </h2>
            <h3>{gettext("Sign up for emails")}</h3>
            {!formSubmitted && (
                <div>
                    <Form onSubmit={handleSubmit}>
                        <FormInput
                            id="email_modal_email"
                            name="email_modal_email"
                            type="email"
                            placeholder={gettext("Email address")}
                            required={true}
                            autoComplete="email"
                            value={emailValue}
                            onChange={handleEmailChange}
                            disabled={isSubmitting}
                        />
                        <FormSubmit type="submit" value={gettext("Submit")} />
                    </Form>
                    {formState.errorMessage && <p>{formState.errorMessage}</p>}
                </div>
            )}
            {formSubmitted && <p>{gettext("Thank you for subscribing!")}</p>}
        </section>
    );
};
