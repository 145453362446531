import React from "react";
import { connect } from "react-redux";
import { IRetailStoreWithDistance } from "tsi-common-react/src/models/location.interfaces";
import {
    TDispatchMapper,
    TStateMapper,
} from "tsi-common-react/src/apps/reducers.interfaces";
import { ISyncStoreID } from "tsi-common-react/src/models/nominals";
import {
    focusedStoreIDSelector,
    myStoreSelector,
    storeSelector,
} from "tsi-common-react/src/apps/retail/selectors";
import { Image } from "tsi-common-react/src/common/Image";
import { Dispatchers } from "tsi-common-react/src/apps/retail/dispatchers";
import Link from "tsi-common-react/src/common/Link";
import urls from "tsi-common-react/src/utils/urls";
import { IInStoreOffers } from "../models";
import { InStoreOffers } from "../components/InStoreOffers";
import { RetailStore } from "../components/RetailStore";
import styles from "./StoreLocatorPanel.module.scss";
import { LocationInput } from "tsi-common-react/src/apps/common/containers/LocationInput";
import { closeStoreLocatorPanel } from "tsi-common-react/src/utils/domManipulation";
import iconXClose from "../../svg/x-close.svg";

interface IOwnProps {
    offers: IInStoreOffers | null;
}

interface IReduxProps {
    closestStore: IRetailStoreWithDistance | null;
    stores: IRetailStoreWithDistance[];
    selectedStoreID: ISyncStoreID | null;
}

interface IDispatchProps {
    dispatchers: Dispatchers;
}

interface IProps extends IOwnProps, IReduxProps, IDispatchProps {}

const StoreLocatorPanelContainer = (props: IProps) => {
    const selectedStore = props.selectedStoreID
        ? props.stores.find(
              (store) => store.external_id === props.selectedStoreID,
          )
        : props.closestStore;

    const otherStores = props.stores
        ? props.stores
              .filter(
                  (store) => store.external_id !== selectedStore?.external_id,
              )
              .slice(0, 4)
        : [];

    return (
        <div className={styles.root}>
            <header className={styles.header}>
                <button onClick={closeStoreLocatorPanel}>
                    <Image
                        src={iconXClose}
                        alt={gettext("Close Panel")}
                        lazy={false}
                    />
                </button>
                <LocationInput
                    inputProps={{
                        id: "store-locator-panel-search",
                        placeholder: "zip code, city, state",
                    }}
                    inputFormat="long"
                />
            </header>
            {selectedStore && <RetailStore store={selectedStore} isSelected />}
            <hr />
            <InStoreOffers offers={props.offers} />
            <hr />
            <h4 className={styles.otherStoresHeader}>Other Stores</h4>
            {otherStores?.map((store) => (
                <RetailStore
                    key={JSON.stringify(store.external_id)}
                    store={store}
                    isSelected={false}
                    setSelectedStoreID={props.dispatchers.setFocusedStore}
                />
            ))}
            <Link
                href={urls.pageURL("locations")}
                target="_blank"
                className={styles.allStoresLink}
            >
                View All Stores
            </Link>
        </div>
    );
};

const mapStateToProps: TStateMapper<
    "configurator" | "retail",
    IReduxProps,
    IOwnProps
> = (rootState, ownProps) => {
    return {
        closestStore: myStoreSelector(rootState),
        stores: storeSelector(rootState),
        selectedStoreID: focusedStoreIDSelector(rootState),
        // Direct Props
        ...ownProps,
    };
};

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = (dispatch) => {
    const dispatchers = new Dispatchers(dispatch);
    return {
        dispatchers: dispatchers,
    };
};

export const StoreLocatorPanel = connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoreLocatorPanelContainer);
