import React from "react";
import Link from "tsi-common-react/src/common/Link";
import urls from "tsi-common-react/src/utils/urls";
import styles from "./StoreDetails.module.scss";
import { StoreHours } from "../../Common/components/StoreHours";
import { IRetailStoreHolidayHours } from "tsi-common-react/src/models/location.interfaces";
import clipboard from "../../img/book-appointment/clipboard.png";

interface IProps {
    hoursSummary: string;
    holidayHours?: IRetailStoreHolidayHours[];
    showBookApptLink: boolean;
}

export const StoreDetails = (props: IProps) => {
    return (
        <div className={styles.root}>
            <p className={styles.title}>Store Hours</p>
            <StoreHours
                hoursSummary={props.hoursSummary}
                holidayHours={props.holidayHours}
            />
            {props.showBookApptLink && (
                <div className={styles.appointmentLink}>
                    <img src={clipboard} alt="" />

                    <Link
                        href={urls.pageURL("book-appointment")}
                        target="_blank"
                        rel="noreferrer"
                    >
                        {gettext("Book An Appointment")}
                    </Link>
                </div>
            )}
        </div>
    );
};
