import React from "react";
import SVG from "react-inlinesvg";
import classNames from "classnames";
import { IRetailStoreWithDistance } from "tsi-common-react/src/models/location.interfaces";
import { MapDirectionsLink } from "tsi-common-react/src/common/MapDirectionsLink";
import { RetailStoreOpenStatus } from "tsi-common-react/src/common/RetailStoreOpenStatus";
import {
    formatAddress,
    formatDistanceWithSuffix,
} from "tsi-common-react/src/apps/retail/helpers";
import RatingGraphic from "tsi-common-react/src/common/RatingGraphic";
import { ISyncStoreID } from "tsi-common-react/src/models/nominals";
import Link from "tsi-common-react/src/common/Link";
import styles from "./RetailStore.module.scss";
import { StoreDetails } from "./StoreDetails";
import iconPin from "../../svg/pin.svg";
import { getPageSetting } from "tsi-common-react/src/utils/settings";

interface IProps {
    store: IRetailStoreWithDistance | null;
    isSelected?: boolean;
    setSelectedStoreID?: (externalID: ISyncStoreID) => void;
}

interface IState {
    isStoreDetailsOpen: boolean;
}

export class RetailStore extends React.Component<IProps, IState> {
    public state: IState = {
        isStoreDetailsOpen: false,
    };

    private readonly toggleStoreDetails = () => {
        this.setState({
            isStoreDetailsOpen: !this.state.isStoreDetailsOpen,
        });
    };

    private readonly selectStore = () => {
        if (this.props.setSelectedStoreID && this.props.store) {
            this.props.setSelectedStoreID(this.props.store.external_id);
        }
    };

    render() {
        if (!this.props.store) {
            return null;
        }
        const storeDetailsClasses = classNames({
            [styles.storeDetailsLink]: true,
            [styles.storeDetailsLinkOpen]: this.state.isStoreDetailsOpen,
        });
        const isSelectable = !this.props.isSelected;
        return (
            <div className={styles.root}>
                {this.props.isSelected && (
                    <h4 className={styles.closestStoreHeader}>
                        Selected Store
                    </h4>
                )}
                <div className={styles.container}>
                    <div className={styles.containerLeft}>
                        <p className={styles.storeName}>
                            <Link href={this.props.store.web}>
                                {this.props.store.name}
                            </Link>
                        </p>
                        {this.props.isSelected && (
                            <div className={styles.rating}>
                                <RatingGraphic
                                    cardClass="reviews"
                                    cardSize="large"
                                    onlyStars={false}
                                    starHasStroke={true}
                                    rating={
                                        this.props.store
                                            .average_review_rating || null
                                    }
                                    numReviews={this.props.store.num_reviews}
                                />
                            </div>
                        )}
                        {this.props.store && (
                            <address>{formatAddress(this.props.store)}</address>
                        )}
                        <span className={styles.phoneNumber}>
                            {this.props.store.phone}
                        </span>
                        <RetailStoreOpenStatus store={this.props.store} />
                        {isSelectable && (
                            <button onClick={this.selectStore}>
                                Select Store
                            </button>
                        )}
                    </div>
                    <div className={styles.containerRight}>
                        <div className={styles.distanceContainer}>
                            <div>
                                <SVG
                                    src={iconPin}
                                    className={styles.distancePin}
                                    title={gettext(
                                        `Distance to ${this.props.store.name}`,
                                    )}
                                    aria-hidden="false"
                                />
                                <p>
                                    {formatDistanceWithSuffix(
                                        this.props.store.distance,
                                    )}
                                </p>
                            </div>
                            <MapDirectionsLink
                                addressParts={[
                                    this.props.store.address,
                                    this.props.store.address2,
                                    this.props.store.city,
                                    this.props.store.state,
                                    this.props.store.postal,
                                ]}
                                className={styles.directionsLink}
                            />
                        </div>
                        <div
                            className={storeDetailsClasses}
                            onClick={this.toggleStoreDetails}
                        >
                            <span>{gettext("Store Details")}</span>
                        </div>
                    </div>
                </div>
                {this.state.isStoreDetailsOpen &&
                    this.props.store.hours_summary && (
                        <StoreDetails
                            hoursSummary={this.props.store.hours_summary}
                            holidayHours={this.props.store.holiday_hours}
                            showBookApptLink={
                                getPageSetting("app-slug") !==
                                "sleepoutfittersoutlet"
                            }
                        />
                    )}
                {!this.props.isSelected && <hr />}
            </div>
        );
    }
}
