import * as t from "io-ts";
import {
    ILatitude,
    ILongitude,
    Latitude,
    Longitude,
    WebPageURL,
    Meters,
    WagtailPageID,
    IWagtailPageID,
    isoSyncStoreID,
    isoMeters,
} from "tsi-common-react/src/models/nominals";
import {
    RetailStoreBusinessHourPeriod,
    RetailStoreHolidayHours,
} from "tsi-common-react/src/models/location";
import { IRetailStoreWithDistance } from "tsi-common-react/src/models/location.interfaces";
import { check, nullable } from "tsi-common-react/src/models/utils";
import { ajax } from "tsi-common-react/src/utils/ajax";

type ITextQuery = {
    // Search location by string
    q: string;
};

type IGeoQuery = {
    // Search location by latitude and longitude
    origLat: ILatitude;
    origLng: ILongitude;
};

export type IQueryParams = ITextQuery | IGeoQuery;

export const RetailLocationPage = t.interface({
    id: WagtailPageID,
    meta: t.interface({
        type: t.string,
        detail_url: t.string,
        html_url: WebPageURL,
        slug: t.string,
        first_published_at: t.string,
        sync_id: t.number,
    }),
    title: t.string,
    address: t.string,
    address2: t.string,
    city: t.string,
    state: t.string,
    postal: t.string,
    lat: Latitude,
    lng: Longitude,
    distance: nullable(Meters),
    upcoming_hours: t.array(RetailStoreBusinessHourPeriod),
    hours_summary: t.string,
    num_reviews: t.number,
    average_review_rating: nullable(t.number),
    phone: t.string,
    storefront: nullable(
        t.interface({
            id: t.number,
            meta: t.interface({
                type: t.string,
                detail_url: t.string,
                download_url: t.string,
            }),
            title: t.string,
        }),
    ),
    storefront_thumbnail: nullable(
        t.interface({
            url: t.string,
            width: t.number,
            height: t.number,
        }),
    ),
    holiday_hours: t.array(RetailStoreHolidayHours),
});

export type RetailLocationPage = t.TypeOf<typeof RetailLocationPage>;

export const RetailLocationPages = t.array(RetailLocationPage);

export const RetailLocationPagesAPIResponse = t.interface({
    meta: t.interface({
        total_count: t.number,
    }),
    items: RetailLocationPages,
});

export type RetailLocationPagesAPIResponse = t.TypeOf<
    typeof RetailLocationPagesAPIResponse
>;

export const convertStorePageShape = (
    page: RetailLocationPage,
): IRetailStoreWithDistance => {
    return {
        id: null,
        url: page.meta.detail_url,
        name: page.title,
        name_slug: page.meta.slug,
        lat: page.lat,
        lng: page.lng,
        address: page.address,
        address2: page.address2,
        city: page.city,
        city_slug: "",
        state: page.state,
        country: "US",
        postal: page.postal,
        external_id: isoSyncStoreID.wrap(page.meta.sync_id),
        data: {},
        category: "",
        featured: false,
        elite: false,
        phone: page.phone,
        inventory: [],
        web: page.meta.html_url,
        distance: page.distance ?? isoMeters.wrap(Infinity),
        upcoming_hours: page.upcoming_hours,
        hours_summary: page.hours_summary,
        num_reviews: page.num_reviews,
        average_review_rating: page.average_review_rating,
        storefront: page.storefront,
        storefront_thumbnail: page.storefront_thumbnail,
        holiday_hours: page.holiday_hours,
    };
};

export const searchStores = async (
    query: IQueryParams,
): Promise<RetailLocationPagesAPIResponse> => {
    const resp = await ajax
        .get(`/api/cms/v2/retail-location-pages/`)
        .set("Accept", "application/json")
        .query({
            fields: "*",
            ...query,
        });
    return check(RetailLocationPagesAPIResponse.decode(resp.body));
};

export const getRetailLocationPageByID = async (
    storeID: IWagtailPageID,
    query: IQueryParams | null,
): Promise<RetailLocationPage> => {
    const resp = await ajax
        .get(`/api/cms/v2/retail-location-pages/${storeID}/`)
        .set("Accept", "application/json")
        .query({
            fields: "*",
            ...query,
        });
    return check(RetailLocationPage.decode(resp.body));
};
