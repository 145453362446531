import React, { useState, useEffect, useRef } from "react";
import SVG from "react-inlinesvg";
import classNames from "classnames";
import { connect } from "react-redux";
import { IRetailStoreWithDistance } from "tsi-common-react/src/models/location.interfaces";
import { TStateMapper } from "tsi-common-react/src/apps/reducers.interfaces";
import {
    focusedStoreIDSelector,
    myStoreSelector,
    storeSelector,
} from "tsi-common-react/src/apps/retail/selectors";
import {
    formatDistanceWithSuffix,
    getAddress,
    getSelectedStore,
} from "tsi-common-react/src/apps/retail/helpers";
import urls from "tsi-common-react/src/utils/urls";
import { Link } from "tsi-common-react/src/common/Link";
import { MapDirectionsLink } from "tsi-common-react/src/common/MapDirectionsLink";

import styles from "./StickyFooter.module.scss";
import { ISyncStoreID } from "tsi-common-react/src/models/nominals";
import iconPin from "../../svg/pin.svg";
import iconStore from "../../svg/store.svg";
import iconClipboard from "../../svg/clipboard.svg";
import iconPhoneWhiteIcon from "../../img/icons/phone-icon-white.svg";
import { getPageSetting } from "tsi-common-react/src/utils/settings";

export const StickyFooterStoreCard = (props: {
    selectedStore: IRetailStoreWithDistance;
    store: IRetailStoreWithDistance;
    idx: number;
}) => {
    const buttonClasses = classNames({
        "button": true,
        "button--secondary":
            props.selectedStore.external_id !== props.store.external_id,
    });
    return (
        <div className={styles.card}>
            <span className={styles.distance}>
                <SVG src={iconPin} />
                {formatDistanceWithSuffix(props.store.distance)}
            </span>
            <Link href={props.store.web} className={styles.cardName}>
                {props.store.name}
            </Link>
            <p className={styles.address}>{getAddress(props.store)}</p>
            <MapDirectionsLink
                className={buttonClasses}
                addressParts={[
                    props.store.address,
                    props.store.address2,
                    props.store.city,
                    props.store.state,
                    props.store.postal,
                ]}
            />
            {props.idx === 0 && (
                <span className="flair flair--sweet-corn-pill">
                    Closest Store
                </span>
            )}
        </div>
    );
};

export const StickyFooterPanel = (props: {
    selectedStore: IRetailStoreWithDistance;
    stores: IRetailStoreWithDistance[];
    expanded: boolean;
}) => {
    return (
        <div className={styles.panel} aria-expanded={props.expanded}>
            {props.stores.map((store, idx) => {
                return (
                    <StickyFooterStoreCard
                        key={`card-${idx}`}
                        store={store}
                        idx={idx}
                        selectedStore={props.selectedStore}
                    />
                );
            })}
            <Link
                href={urls.pageURL("locations")}
                className={styles.viewAllStores}
            >
                {gettext("View All Stores")}
            </Link>
        </div>
    );
};

export const StickyFooterComponent = (props: {
    closestStore: IRetailStoreWithDistance;
    selectedStore: IRetailStoreWithDistance;
    stores: IRetailStoreWithDistance[];
}) => {
    const [expanded, setExpanded] = useState(false);
    const wrapperRef = useRef<HTMLDivElement | null>(null);

    const handleClickOutside = (event: MouseEvent) => {
        if (
            wrapperRef.current &&
            !wrapperRef.current.contains(event.target as Node)
        ) {
            setExpanded(false);
        }
    };

    useEffect(() => {
        // Add when mounted
        document.addEventListener("mousedown", handleClickOutside);
        // Return function to be called when unmounted
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const buttonClasses = classNames({
        [styles.button]: true,
        [styles.panelClosed]: !expanded,
    });

    const appSlugName = getPageSetting("app-slug");
    const isOutletSite = appSlugName === "sleepoutfittersoutlet";

    return (
        <section className={styles.wrapper} ref={wrapperRef}>
            <div className={styles.root}>
                <button
                    className={buttonClasses}
                    onClick={() => {
                        setExpanded(!expanded);
                    }}
                >
                    <SVG src={iconStore} />
                    <span className={styles.name}>
                        {props.selectedStore.name}
                    </span>
                    <span className={styles.divider}>|</span>
                    {formatDistanceWithSuffix(props.selectedStore.distance)}
                </button>
                {isOutletSite ? (
                    <Link
                        href={urls.pageURL("customer-service")}
                        className={styles.appointmentLink}
                    >
                        <SVG src={iconPhoneWhiteIcon} />
                        {gettext("Contact Us")}
                    </Link>
                ) : (
                    <Link
                        href={urls.pageURL("book-appointment")}
                        className={styles.appointmentLink}
                    >
                        <SVG src={iconClipboard} />
                        {gettext("Book an Appointment")}
                    </Link>
                )}
            </div>
            {expanded && (
                <StickyFooterPanel
                    stores={props.stores}
                    expanded={expanded}
                    selectedStore={props.selectedStore}
                />
            )}
        </section>
    );
};

interface IOwnProps {}

interface IReduxProps {
    closestStore: IRetailStoreWithDistance | null;
    stores: IRetailStoreWithDistance[];
    selectedStoreID: ISyncStoreID | null;
}

interface IProps extends IOwnProps, IReduxProps {}

const StickyFooterContainer = (props: IProps) => {
    const selectedStore = getSelectedStore(
        props.closestStore,
        props.selectedStoreID,
        props.stores,
    );
    if (!selectedStore) {
        return null;
    }
    return (
        <StickyFooterComponent
            closestStore={selectedStore}
            stores={props.stores.slice(0, 4)}
            selectedStore={selectedStore}
        />
    );
};

const mapStateToProps: TStateMapper<"retail", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    return {
        closestStore: myStoreSelector(rootState),
        stores: storeSelector(rootState),
        selectedStoreID: focusedStoreIDSelector(rootState),
        // Direct Props
        ...ownProps,
    };
};

export const StickyFooter = connect(mapStateToProps)(StickyFooterContainer);
