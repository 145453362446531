import React from "react";
import { format } from "tsi-common-react/src/utils/format";

interface IProps {
    termAPR: string;
    termMonths: number;
    termThreshold: string;
    promoPeriod: string;
    aprInfoID: string;
}

export const UnifiPreQualAdHeader = (props: IProps) => (
    <>
        <h2 className="pre-qual-block__header">
            {interpolate(
                gettext("%(termYears)s Years — %(apr)s% Interest"),
                {
                    apr: props.termAPR,
                    termYears: `${props.termMonths / 12}`,
                },
                true,
            )}
            <sup>
                <a href={props.aprInfoID}>1</a>
            </sup>
        </h2>
        <p>
            {interpolate(
                gettext(
                    "On all purchases of %(threshold)s or more made with your Sleep Outfitters credit card from %(promoPeriod)s. Equal monthly payments required for %(termMonths)s months. Promo fee of 2% of amount financed will be included in required monthly payments.¹",
                ),
                {
                    threshold: format.money(props.termThreshold),
                    promoPeriod: `${props.promoPeriod}`,
                    termMonths: `${props.termMonths}`,
                },
                true,
            )}
        </p>
    </>
);
