import React from "react";
import SVG from "react-inlinesvg";
import classNames from "classnames";
import { BreakPoint } from "tsi-common-react/src/models/screen.interfaces";
import {
    isMobile,
    getViewportBreakpoint,
} from "tsi-common-react/src/utils/detectMobile";
import { Modal, ModalStyles } from "tsi-common-react/src/common/Modal";
import {
    WindowResizeSensor,
    OnResizeHandler,
} from "tsi-common-react/src/common/WindowResizeSensor";
import {
    IMenuCategoryLink,
    IMenuItem,
    IMenuPageLink,
    IMenuPageLinkWithImage,
    IMenuSnippetLink,
    IMenuPromo,
} from "../menu.interface";
import { PageLink } from "./menu/PageLink";
import { CategoryLink } from "./menu/CategoryLink";
import { DropdownLinks } from "./menu/DropdownLinks";
import { MenuAdverts } from "./menu/MenuAdverts";
import { NavFooter } from "./menu/NavFooter";
import { PromoAd } from "./menu/PromoAd";
import { focusElement } from "tsi-common-react/src/utils/keyboardFocus";
import iconBars from "../../svg/bars.svg";
import iconXClose from "../../svg/x-close.svg";
import iconCaretDown from "../../svg/caret-down.svg";

interface IProps {
    menuItems: IMenuItem[];
}

interface IState {
    dropdownOffsetTop: number;
    isMobile: boolean;
    iframeDocHeight: number | null;
    modalIsOpen: boolean;
    activeMenuItem: IMenuCategoryLink | null;
    submenuParentElement: HTMLElement | null;
}

export class MainMenu extends React.Component<IProps, IState> {
    state: IState = {
        dropdownOffsetTop: 0,
        isMobile: false,
        iframeDocHeight: null,
        modalIsOpen: false,
        activeMenuItem: null,
        submenuParentElement: null,
    };

    private _iframeHeightCallbackSet = false;

    private isSmallScreenWidth() {
        return getViewportBreakpoint() < BreakPoint.SMALL;
    }

    componentDidUpdate() {
        const mobile = this.isSmallScreenWidth();
        if (mobile !== this.state.isMobile) {
            this.setState({
                isMobile: mobile,
            });
        }

        this.setIframeHeightCallback();
    }

    componentDidMount() {
        if (this.isSmallScreenWidth()) {
            this.setState({
                isMobile: true,
            });
        }
        const dropdownTopPos = document.querySelector<HTMLElement>(".site-nav");
        const getScrollTop =
            window.pageYOffset || document.documentElement.scrollTop;
        if (dropdownTopPos) {
            this.setState({
                dropdownOffsetTop:
                    dropdownTopPos.getBoundingClientRect().bottom +
                    getScrollTop,
            });
        }

        this.setIframeHeightCallback();
    }

    private readonly onOpenModal = (
        event?: React.MouseEvent<HTMLElement>,
        subCategory?: IMenuCategoryLink,
    ) => {
        let menuLinkElem: HTMLElement | null = null;
        if (event) {
            event.preventDefault();
            menuLinkElem = event.currentTarget;
        }
        if (menuLinkElem) {
            if (
                !menuLinkElem.classList.contains(
                    "site-nav__main-menu-item--mobile",
                )
            ) {
                document.body.classList.add("body-dropdown-open--desktop");
            } else {
                const layout = document.querySelector(".layout");
                if (layout) {
                    layout.classList.add("layout--hidden");
                }
            }
        }

        this.setState({
            modalIsOpen: true,
            activeMenuItem: subCategory || null,
            submenuParentElement: menuLinkElem,
        });

        if (subCategory) {
            setTimeout(() => {
                focusElement(".main-dropdown-menu__back");
            }, 300);
        }
    };

    private readonly onCloseModal = (
        event?: React.MouseEvent<Element> | React.KeyboardEvent<Element>,
    ) => {
        let menuLinkElem: HTMLElement | null = null;
        if (event) {
            event.preventDefault();
            menuLinkElem = event.currentTarget as HTMLElement;
        }
        if (menuLinkElem) {
            if (
                !menuLinkElem.classList.contains(
                    "site-nav__main-menu-item--mobile",
                )
            ) {
                document.body.classList.remove("body-dropdown-open--desktop");
                const layout = document.querySelector(".layout--hidden");
                if (layout) {
                    layout.classList.remove("layout--hidden");
                }
            }
        }
        this.setState({
            modalIsOpen: false,
            activeMenuItem: null,
        });
    };

    private readonly onCloseSubNav = () => {
        const parentElem = this.state.submenuParentElement;
        this.setState({
            submenuParentElement: null,
            activeMenuItem: null,
        });
        if (parentElem) {
            window.setTimeout(() => {
                parentElem.focus();
            }, 300);
        }
    };

    private readonly onResize: OnResizeHandler = (event) => {
        // If only the height changed, don't do anything
        if (event.type === "height") {
            return;
        }
        // Test for mobile view on android
        if (this.isSmallScreenWidth()) {
            this.setState({
                isMobile: true,
            });
            if (isMobile.Android()) {
                return;
            }
        } else {
            this.setState({
                isMobile: false,
            });
        }
        if (this.state.modalIsOpen) {
            // If the modal is open and the window is resized, close the modal
            this.onCloseModal();
        }
    };

    private setIframeHeightCallback() {
        if (this._iframeHeightCallbackSet) {
            return;
        }
        if (!window.parentIFrame) {
            return;
        }
        // If the menu is in an iframe, we need to set the height of the mobile menu based
        // on it, since we can't rely on height: 100% to actually mean anything from
        // inside the frame).
        window.parentIFrame.getPageInfo((info) => {
            this.setState({
                iframeDocHeight: info.windowHeight,
            });
        });
        this._iframeHeightCallbackSet = true;
    }

    private buildNavAdvert(menuPromo: IMenuPromo): JSX.Element {
        let index = 0;
        const advert = (
            <li>
                <div className="main-dropdown-menu__adverts">
                    <PromoAd
                        promoArrayEl={menuPromo}
                        promoID={index++}
                        key={index++}
                    />
                </div>
            </li>
        );

        return advert;
    }

    private buildCategoryLinks(menuItem: IMenuCategoryLink): JSX.Element[] {
        const links: JSX.Element[] = [];
        let index = 0;

        const link = (
            <li key={menuItem.value.title}>
                <CategoryLink
                    linkObject={menuItem}
                    isMobile={this.state.isMobile}
                    linkID={index++}
                    openModal={this.onOpenModal}
                />
            </li>
        );
        links.push(link);

        return links;
    }

    private buildPageLink(
        menuItem: IMenuPageLink,
        linkID: number,
    ): JSX.Element {
        return (
            <li key={menuItem.value.override_title}>
                <PageLink
                    linkObject={menuItem}
                    isMobile={this.state.isMobile}
                    linkID={linkID}
                />
            </li>
        );
    }

    private buildMenuLinks() {
        let index = 0;
        const menuLinks = this.props.menuItems.reduce<JSX.Element[]>(
            (memo, menuItem) => {
                switch (menuItem.type) {
                    case "nav_category":
                        return memo.concat(this.buildCategoryLinks(menuItem));
                    case "page_link":
                        return memo.concat(
                            this.buildPageLink(menuItem, index++),
                        );
                    case "nav_advert":
                        if (this.state.isMobile) {
                            return memo.concat(this.buildNavAdvert(menuItem));
                        }
                }
                return memo;
            },
            [],
        );
        return menuLinks;
    }

    render() {
        let contentPosition: React.CSSProperties["position"];
        let contentTop: React.CSSProperties["top"];
        let contentHeight: React.CSSProperties["height"];
        if (this.state.isMobile) {
            contentPosition = "fixed";
            contentTop = "0px";
            contentHeight = this.state.iframeDocHeight
                ? `${this.state.iframeDocHeight}px`
                : "100%";
        } else {
            contentPosition = "relative";
            contentTop = `${this.state.dropdownOffsetTop - 20}px`;
            contentHeight = "auto";
        }

        const modalStyle: ModalStyles = {
            overlay: {
                position: "absolute",
                backgroundColor: "rgba(255, 255, 255, 0)",
            },
            content: {
                position: contentPosition,
                top: contentTop,
                marginTop: "0",
                width: "100%",
                height: contentHeight,
                overflow: "hidden",
                border: "none",
                boxShadow: "0 10px 10px -5px rgba(0,0,0,.3)",
            },
        };

        const dropdownLinks = this.state.activeMenuItem ? (
            <DropdownLinks
                isActive={true}
                isMobile={this.state.isMobile}
                linksArray={this.state.activeMenuItem.value.sub_nav.filter(
                    (val): val is IMenuPageLinkWithImage | IMenuSnippetLink => {
                        return (
                            val.type === "page_link_with_image" ||
                            val.type === "snippet_link"
                        );
                    },
                )}
                linkID={0}
                key={0}
            />
        ) : null;

        const promoAds: JSX.Element[] = [];
        let navFooter: JSX.Element | null = null;

        if (
            this.state.activeMenuItem &&
            this.state.activeMenuItem.value.sub_nav.length > 0
        ) {
            // Build out Nav c Ad(s) (if it/they exists)
            this.state.activeMenuItem.value.sub_nav.forEach((val, i) => {
                switch (val.type) {
                    case "nav_advert":
                        promoAds.push(
                            <PromoAd promoArrayEl={val} promoID={i} key={i} />,
                        );
                        break;
                    case "nav_footer":
                        navFooter = <NavFooter navFooterEl={val} />;
                        break;
                    default:
                        break;
                }
            });
        }

        // Label of the currently selected menu. To be used as the value
        // for the contentLabel prop, used by the Modal component
        let activeMenuLabel = "";
        if (this.state.activeMenuItem) {
            activeMenuLabel = this.state.activeMenuItem.value.title;
            activeMenuLabel = interpolate(gettext("%s Menu"), [
                activeMenuLabel,
            ]);
        } else {
            activeMenuLabel = "";
        }

        const dropdownContentClases = classNames({
            "main-dropdown-menu__content": true,
            "main-dropdown-menu__content--closed": this.state.activeMenuItem,
            "l-capped-width": true,
        });

        const dropdownSubContentClases = classNames({
            "main-dropdown-menu__sub-content": true,
            "main-dropdown-menu__sub-content--open": this.state.activeMenuItem,
            "l-capped-width": true,
        });

        if (this.state.isMobile) {
            return (
                <>
                    <WindowResizeSensor onResize={this.onResize} />
                    <button
                        tabIndex={0}
                        aria-label={gettext("Open Menu")}
                        title={gettext("Open Menu")}
                        onClick={() => {
                            this.onOpenModal();
                        }}
                    >
                        <SVG
                            className="site-nav__mobile-toggle-icon"
                            src={iconBars}
                            title={gettext("Menu Icon")}
                            aria-hidden="true"
                        />
                    </button>
                    <Modal
                        aria={{
                            modal: true,
                        }}
                        contentLabel={activeMenuLabel}
                        isOpen={this.state.modalIsOpen}
                        style={modalStyle}
                        onRequestClose={this.onCloseModal}
                        role="dialog"
                        onAfterOpen={() => {
                            focusElement(
                                ".main-dropdown-menu .main-dropdown-menu__close",
                            );
                        }}
                        closeBtnProps={{
                            style: {
                                top: "0.5rem",
                                right: "0.5rem",
                            },
                        }}
                    >
                        <h1 className="ada-screenreader-only">
                            {gettext("Shop Menu")}
                        </h1>
                        <div className="main-dropdown-menu">
                            <button
                                aria-label={gettext("Close Menu")}
                                onClick={this.onCloseModal}
                                className="main-dropdown-menu__close"
                            >
                                <SVG
                                    className="main-dropdown-menu__close-icon"
                                    src={iconXClose}
                                    aria-hidden="true"
                                />
                            </button>
                            <div
                                id="main-menu"
                                className={dropdownContentClases}
                                aria-expanded={!this.state.activeMenuItem}
                                aria-label={gettext(
                                    "Mattresses, Bed Bases, Pillows, Bedding, and More From Tempur",
                                )}
                            >
                                <ul>{this.buildMenuLinks()}</ul>
                            </div>
                            <div
                                className={dropdownSubContentClases}
                                aria-expanded={!!this.state.activeMenuItem}
                                id="menu-subnav"
                            >
                                <button
                                    title={gettext("Back")}
                                    className="main-dropdown-menu__back"
                                    onClick={this.onCloseSubNav}
                                >
                                    <SVG
                                        className="main-dropdown-menu__back-icon"
                                        src={iconCaretDown}
                                        title={gettext("Dropdown Menu Icon")}
                                        aria-hidden="true"
                                    />
                                    {gettext("Back")}
                                </button>
                                {dropdownLinks}
                                <MenuAdverts promos={promoAds} />
                            </div>
                            {navFooter}
                        </div>
                    </Modal>
                </>
            );
        }

        return (
            <>
                <WindowResizeSensor onResize={this.onResize} />
                <ul>{this.buildMenuLinks()}</ul>
                <Modal
                    contentLabel={activeMenuLabel}
                    isOpen={this.state.modalIsOpen}
                    style={modalStyle}
                    onRequestClose={this.onCloseModal}
                    closeBtnProps={{
                        style: {
                            top: "0.5rem",
                            right: "0.5rem",
                        },
                    }}
                >
                    <h1 className="ada-screenreader-only">
                        {gettext("Shop Menu")}
                    </h1>
                    <div className="main-dropdown-menu">
                        <div
                            id="main-menu"
                            className={dropdownContentClases}
                            aria-expanded={!!this.state.activeMenuItem}
                            aria-label={gettext(
                                "Mattresses, Bed Bases, Pillows, Bedding, and More From Tempur",
                            )}
                        >
                            {dropdownLinks}
                            <MenuAdverts promos={promoAds} />
                        </div>
                        {navFooter}
                    </div>
                </Modal>
            </>
        );
    }
}
