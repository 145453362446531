import React from "react";
import Flickity from "flickity-imagesloaded";
import classNames from "classnames";
import { IRetailStoreWithDistance } from "tsi-common-react/src/models/location.interfaces";
import { ISyncStoreID } from "tsi-common-react/src/models/nominals";
import { MarkerMap } from "tsi-common-react/src/apps/retail/containers/MarkerMap";
import { LocationsColumnBlockTile } from "./LocationsColumnBlockTile";
import { LocationInput } from "tsi-common-react/src/apps/common/containers/LocationInput";
import { urls } from "tsi-common-react/src/utils/urls";
import { Link } from "tsi-common-react/src/common/Link";
import styles from "./LocationsColumnBlock.module.scss";
import fsMarker from "../../img/retail-locator/fs-marker.png";
import { getPageSetting } from "tsi-common-react/src/utils/settings";

interface IProps {
    stores: IRetailStoreWithDistance[];
    withMap: boolean;
    onSubmit?: () => void;
}

interface IState {
    focusedTileID: ISyncStoreID | null;
    maxTiles: number;
}

export class LocationsColumnBlockComponent extends React.Component<
    IProps,
    IState
> {
    state: IState = {
        focusedTileID: null,
        maxTiles: 4,
    };

    private readonly tilesElement = React.createRef<HTMLUListElement>();
    private readonly buttonsRef = React.createRef<HTMLDivElement>();
    private flickity: Flickity | null = null;

    componentDidMount() {
        if (!this.props.withMap) {
            this.initFlickity();
        }
    }

    componentDidUpdate() {
        if (!this.props.withMap) {
            this.initFlickity();
        }
    }

    shouldComponentUpdate(): boolean {
        if (this.flickity) {
            this.flickity.destroy();
        }
        return true;
    }

    private flickityOnSelect() {
        if (!this.flickity || !this.buttonsRef.current) {
            return;
        }

        const buttonGroup = this.buttonsRef.current;
        if (!buttonGroup) {
            return;
        }

        this.flickity.on("select", (index) => {
            const selectedButton = buttonGroup.children[Number(index)];
            Array.from(buttonGroup.children, (button) => {
                if (button !== selectedButton) {
                    button.classList.remove("is-selected");
                    button.setAttribute("aria-current", "false");
                } else {
                    button.classList.add("is-selected");
                    button.setAttribute("aria-current", "true");
                }
            });
        });
    }

    private initFlickity() {
        if (this.flickity) {
            this.flickity.destroy();
        }
        if (!this.tilesElement.current) {
            return;
        }
        this.flickity = new Flickity(this.tilesElement.current, {
            imagesLoaded: true,
            prevNextButtons: false,
            watchCSS: true,
            contain: true,
            pageDots: false,
            setGallerySize: false,
        });
        this.flickityOnSelect();
    }

    private readonly defaultMapCenter = {
        lat: 37.0902,
        lng: -95.7129,
    };
    private readonly defaultZoom = 4;
    private readonly mapElem = React.createRef<MarkerMap>();

    private readonly onBeforeInfoWindowOpen = (
        info: google.maps.InfoWindow,
        store: IRetailStoreWithDistance,
    ) => {
        const content = `<div className="loc__info-window">
            <a href="${store.web}">
                ${store.city}, ${store.state}
            </a>
        </div>`;
        info.setContent(content);
        this.setState({
            focusedTileID: store.external_id,
        });
    };

    private readonly onInfoWindowClose = () => {
        this.setState({
            focusedTileID: null,
        });
    };

    private renderTiles() {
        return this.props.stores
            .slice(0, this.state.maxTiles)
            .map((store) => (
                <LocationsColumnBlockTile
                    key={JSON.stringify(store.external_id)}
                    store={store}
                    withMap={this.props.withMap}
                    showBookApptLink={
                        getPageSetting("app-slug") !== "sleepoutfittersoutlet"
                    }
                />
            ));
    }

    private selectFlickity(index: number) {
        if (!this.flickity) {
            return;
        }
        this.flickity.select(index);
    }

    private readonly onSearchChange = () => {
        this.setState({
            maxTiles: 5,
        });
        if (this.props.onSubmit) {
            this.props.onSubmit();
        }
    };

    render() {
        const locationInputID = "locations-block-autocomplete";
        const listClassNames = classNames({
            [styles.list]: true,
            [styles.listWithoutMap]: !this.props.withMap,
        });
        return (
            <>
                {this.props.withMap && (
                    <>
                        <div className={styles.titleBar}>
                            <span>Find a Store</span>
                            <LocationInput
                                inputProps={{
                                    id: locationInputID,
                                    placeholder: "zip code, city, state",
                                }}
                                inputFormat="long"
                                onAfterChange={this.onSearchChange}
                            />
                            <Link
                                href={urls.pageURL("locations")}
                                target="_top"
                                rel="noreferrer"
                            >
                                Complete Store List
                            </Link>
                        </div>

                        <MarkerMap
                            ref={this.mapElem}
                            stores={this.props.stores}
                            markerOptions={() => {
                                return {
                                    icon: fsMarker,
                                };
                            }}
                            elemProps={{
                                "id": "locations__map",
                                "className": "locations__map",
                                "role": "region",
                                "aria-label": "map",
                            }}
                            mapOptions={() => {
                                return {
                                    center: this.defaultMapCenter,
                                    zoom: this.defaultZoom,
                                    scrollwheel: false,
                                    draggable: true,
                                    disableDefaultUI: false,
                                    clickableIcons: false,
                                    mapTypeId: google.maps.MapTypeId.ROADMAP,
                                    styles: [
                                        {
                                            featureType: "all",
                                            elementType: "labels",
                                            stylers: [{ visibility: "on" }],
                                        },
                                        {
                                            featureType: "water",
                                            elementType: "geometry",
                                            stylers: [
                                                { visibility: "simplified" },
                                                { color: "#58585b" },
                                            ],
                                        },
                                        {
                                            featureType: "landscape",
                                            elementType: "all",
                                            stylers: [
                                                { visibility: "simplified" },
                                                { color: "#f2f2f2" },
                                            ],
                                        },
                                        {
                                            featureType: "water",
                                            elementType: "labels",
                                            stylers: [{ visibility: "off" }],
                                        },
                                        {
                                            featureType:
                                                "administrative.country",
                                            elementType: "labels",
                                            stylers: [
                                                {
                                                    visibility: "off",
                                                },
                                            ],
                                        },
                                        {
                                            featureType:
                                                "administrative.locality",
                                            stylers: [
                                                {
                                                    visibility: "off",
                                                },
                                            ],
                                        },
                                    ],
                                };
                            }}
                            onBeforeInfoWindowOpen={this.onBeforeInfoWindowOpen}
                            onInfoWindowClose={this.onInfoWindowClose}
                        />
                    </>
                )}
                <section
                    className={styles.listWrapper}
                    role="region"
                    aria-label="Locations"
                >
                    <ul className={listClassNames} ref={this.tilesElement}>
                        {this.renderTiles()}
                    </ul>
                    {!this.props.withMap && (
                        <div className={styles.buttons} ref={this.buttonsRef}>
                            {this.props.stores
                                .slice(0, this.state.maxTiles)
                                .map((_, i) => (
                                    <button
                                        className={styles.button}
                                        aria-label={`Slide ${i} of ${this.props.stores.length}`}
                                        key={i}
                                        aria-current={i === 0}
                                        onClick={() => this.selectFlickity(i)}
                                    />
                                ))}
                        </div>
                    )}
                </section>
            </>
        );
    }
}
