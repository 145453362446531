import config from "tsi-common-react/src/config";
import { strings } from "tsi-common-react/src/localization";
import { strToBool } from "tsi-common-react/src/utils/format";
import { getPageSetting } from "tsi-common-react/src/utils/settings";

config.update({
    RETAIL_LOCATOR_API: getPageSetting("retail-locator-api"),
    RETAIL_LOCATOR_COUNTRY: getPageSetting("retail-locator-country"),
    RETAIL_LOCATOR_DISPLAY_ENABLED: getPageSetting("retail-locator-display"),
    METRICS_API: getPageSetting("metrics-api"),
    REVIEWS_API: getPageSetting("reviews-api"),
    REVIEWS_DISCLOSURE_SETTINGS: getPageSetting("reviews-disclosures-settings"),
    ENABLE_ECOM: strToBool(getPageSetting("enable-ecom")),
    ENABLE_BASKET_ID: strToBool(getPageSetting("enable-basket-id")),
    ENABLE_CHECKOUT_CAPTCHA: true,
    GOOGLE_MAPS_API: getPageSetting("google-maps-api-key"),
    SYF_UNIFY_LIBRARY_URL: getPageSetting("syf-unify-library-url"),
    SYF_UNIFY_PARTNER_ID: getPageSetting("syf-unify-partner-id"),
});

strings.update({
    WEBSITE_SUPPORT_NUMBER: getPageSetting(
        "support-website-phone-number-display",
    ),
    WEBSITE_SUPPORT_NUMBER_LINK: getPageSetting(
        "support-website-phone-number-link",
    ),
    WEBSITE_SUPPORT_CONTACT_HOURS: getPageSetting("support-contact-hours"),

    // Tempur-Pedic
    ORDER_THANK_YOU_B1_CREATE_ACCT_COPY: gettext(
        "Create an account to easily check order status and get updates on delivery, plus register your products and write reviews for all your orders from one convenient location.",
    ),
    ORDER_THANK_YOU_B1_CREATE_ACCT_SUCCESS: gettext(
        "Don't forget, you can easily check order status and get updated on delivery, plus register your products and write reviews for all your orders from your <MyOrdersLink>My Orders</MyOrdersLink> page.",
    ),
    ORDER_THANK_YOU_B3_CREATE_ACCT_COPY: gettext(
        "The email address <UserEmail>EMAIL ADDRESS</UserEmail> is already associated with an account. <LoginLink>Log in</LoginLink> to easily check order status and get updates on delivery, plus register your products and write reviews for all your orders from one convenient location.",
    ),
    ORDER_THANK_YOU_B4_CREATE_ACCT_COPY: gettext(
        "Don't forget, you can easily check order status and get updates on delivery, plus register your products and write reviews for all your orders from your <MyOrdersLink>My Orders</MyOrdersLink> page.",
    ),
});
