import React from "react";
import { connect } from "react-redux";
import {
    IReviewsProductID,
    IReviewsProductVariantID,
    IProductUUID,
    isoFormUUID,
    IWebPageURL,
} from "../../../models/nominals";
import {
    IReviewsProduct,
    IWriteReviewTemplate,
} from "../../../models/reviews.interfaces";
import { getProduct, getWriteReviewTemplate } from "../../../api/reviews";
import {
    IWriteReviewFormState,
    IReviewFormProductTemplatePair,
    IReduxState,
} from "../reducers.interfaces";
import { TStateMapper, TDispatchMapper } from "../../reducers.interfaces";
import { Actions } from "../actions";
import { Dispatchers } from "../dispatchers";
import { defaultState, defaultReviewTemplate } from "../defaults";
import { Form } from "../../../forms/Form";
import { FormSubmit } from "../../../forms/FormSubmit";
import {
    WriteReviewFormMode,
    SubmitReviewStatus,
    SideNames,
} from "../constants";
import { WriteReviewMultiThankYou } from "../elements/WriteReviewMultiThankYou";
import { MultiProductWriteReviewFormSection } from "../elements/MultiProductWriteReviewFormSection";
import { MultiProductWriteReviewFormGlobalInputSection } from "../elements/MultiProductWriteReviewFormGlobalInputSection";
import { WriteReviewFormDisclaimers } from "../elements/WriteReviewFormDisclaimers";
import { getAllFormSubmittedForParentUUID } from "../selectors";

interface IOwnProps {
    parentUUID: IProductUUID;
    productID: IReviewsProductID;
    variantIDs: IReviewsProductVariantID[];
    returnURL?: IWebPageURL;
}

interface IReduxProps {
    allSubmitted: boolean;
    loadedInitialProducts: boolean;
    products: IReviewsProduct[];
    writeReviewTemplates: IReviewFormProductTemplatePair[];
    forms: { [formUUID: string]: IWriteReviewFormState };
}

interface IDispatchProps {
    dispatchers: Dispatchers;
    actions: Actions;
}

interface IProps extends IOwnProps, IReduxProps, IDispatchProps {}

interface IState {}

const getWriteReviewTemplateForForm = (
    templates: IReviewFormProductTemplatePair[],
    formState: IWriteReviewFormState,
): IWriteReviewTemplate => {
    const formTemplate = templates.find(
        ({ productID }) => productID === formState.selectedProductID,
    );
    return formTemplate ? formTemplate.template : defaultReviewTemplate;
};

class HeterogenousProductReviewFormContainer extends React.Component<
    IProps,
    IState
> {
    private readonly onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const isMultiReview = true;
        const submitStatuses = [];
        for (const [uuidstr, formState] of Object.entries(this.props.forms)) {
            const uuid = isoFormUUID.wrap(uuidstr);
            const formTemplate = getWriteReviewTemplateForForm(
                this.props.writeReviewTemplates,
                formState,
            );
            const submitStatus = await this.props.actions.submitReview(
                uuid,
                formTemplate,
                formState,
                isMultiReview,
            );
            submitStatuses.push(submitStatus);
        }
        if (submitStatuses.every((s) => s === SubmitReviewStatus.SUCCESS)) {
            this.scrollToThankYou();
        }
    };

    private scrollToThankYou() {
        const thankYouSelectDiv = document.querySelector(".review-thank-you");
        thankYouSelectDiv?.scrollIntoView({ behavior: "smooth" });
    }

    componentDidMount() {
        this.listProducts();
    }

    componentDidUpdate(prevProps: IProps, _prevState: IReduxState) {
        if (this.props.allSubmitted && !prevProps.allSubmitted) {
            this.scrollToThankYou();
        }
    }

    private async listProducts() {
        const product = await getProduct(this.props.productID);
        this.props.dispatchers.setProducts([product]);
        const template = await getWriteReviewTemplate(this.props.productID);
        this.props.variantIDs.map(async (variantID) => {
            const uuid = isoFormUUID.wrap(
                `${this.props.parentUUID}__${variantID}`,
            );
            this.props.dispatchers.registerNewWriteReviewForm(
                uuid,
                WriteReviewFormMode.FORM_OPEN,
            );
            this.props.dispatchers.setReviewTemplate(
                uuid,
                this.props.productID,
                template,
            );
            // Find variant info from review product, update selection of form
            const reviewVariantInfo = product.variants.find(
                (v) => v.id === variantID,
            );
            if (reviewVariantInfo) {
                this.props.dispatchers.onReviewFormVariantChange({
                    formUUID: uuid,
                    selectedVariant: reviewVariantInfo.slug,
                });
            }
        });
    }

    render() {
        if (!this.props.loadedInitialProducts) {
            return null;
        }
        return (
            <div className="write-review-form multi-product-write-review-form">
                {!this.props.allSubmitted && (
                    <>
                        <p className="write-review-form__sub-title">
                            (
                            <span className="write-review-form__sub-title--red">
                                *
                            </span>
                            ){" "}
                            <span className="write-review-form__sub-title--italic">
                                {gettext("indicates a required field")}
                            </span>
                        </p>
                        <Form onSubmit={this.onSubmit} noValidate={true}>
                            {this.props.products.map((product) => {
                                return this.props.variantIDs.map(
                                    (variantID, idx) => {
                                        const formUUID = isoFormUUID.wrap(
                                            `${this.props.parentUUID}__${variantID}`,
                                        );
                                        const side = gettext(SideNames[idx]);
                                        return (
                                            <MultiProductWriteReviewFormSection
                                                key={String(formUUID)}
                                                formUUID={formUUID}
                                                product={product}
                                                isMultiReview={true}
                                                side={side}
                                            />
                                        );
                                    },
                                );
                            })}
                            ;
                            <MultiProductWriteReviewFormGlobalInputSection />
                            <WriteReviewFormDisclaimers />
                            <FormSubmit
                                className="button button--rock-blue write-review-form__cta"
                                type="submit"
                                value={gettext("Submit your review")}
                            />
                        </Form>
                    </>
                )}
                <WriteReviewMultiThankYou parentUUID={this.props.parentUUID} />
            </div>
        );
    }
}

const mapStateToProps: TStateMapper<"reviews", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    const state = rootState.reviews || defaultState;
    const allFormsSubmitted = getAllFormSubmittedForParentUUID(
        rootState.reviews,
        ownProps,
    );
    return {
        loadedInitialProducts: state.data.loadedInitialProducts,
        writeReviewTemplates: state.data.writeReviewTemplates,
        products: state.data.products,
        forms: state.ui.writeReviewForms,
        allSubmitted: allFormsSubmitted,
        ...ownProps,
    };
};

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = (dispatch) => {
    const dispatchers = new Dispatchers(dispatch);
    const actions = new Actions(dispatchers);
    return {
        dispatchers: dispatchers,
        actions: actions,
    };
};

export const HeterogenousProductReviewForm = connect(
    mapStateToProps,
    mapDispatchToProps,
)(HeterogenousProductReviewFormContainer);
