import React from "react";
import SVG from "react-inlinesvg";
import { connect } from "react-redux";
import { IRetailStoreWithDistance } from "tsi-common-react/src/models/location.interfaces";
import { TStateMapper } from "tsi-common-react/src/apps/reducers.interfaces";
import {
    focusedStoreIDSelector,
    myStoreSelector,
    storeSelector,
} from "tsi-common-react/src/apps/retail/selectors";
import {
    formatAddress,
    formatDistanceWithSuffix,
    getSelectedStore,
} from "tsi-common-react/src/apps/retail/helpers";
import RatingGraphic from "tsi-common-react/src/common/RatingGraphic";
import { ISyncStoreID } from "tsi-common-react/src/models/nominals";
import { RetailStoreOpenStatus } from "tsi-common-react/src/common/RetailStoreOpenStatus";
import Link from "tsi-common-react/src/common/Link";

import styles from "./CurrentStore.module.scss";
import { MapDirectionsLink } from "tsi-common-react/src/common/MapDirectionsLink";
import iconPin from "../../svg/pin.svg";

export const CurrentStoreComponent = (props: {
    store: IRetailStoreWithDistance;
}) => {
    return (
        <div className={styles.container}>
            <div className={styles.containerLeft}>
                <h4 className={styles.header}>Current Store</h4>
                <span className={styles.storeName}>{props.store.name}</span>
                <div className={styles.rating}>
                    <RatingGraphic
                        cardClass="reviews"
                        cardSize="large"
                        onlyStars={false}
                        starHasStroke={true}
                        rating={props.store.average_review_rating || null}
                        numReviews={props.store.num_reviews}
                    />
                </div>
                {props.store && <address>{formatAddress(props.store)}</address>}
                <RetailStoreOpenStatus store={props.store} />
                <Link className={styles.detailsLink} href={props.store.web}>
                    Store Details
                </Link>
            </div>
            <div className={styles.containerRight}>
                <SVG
                    src={iconPin}
                    className={styles.distancePin}
                    title={gettext(`Distance to ${props.store.name}`)}
                    aria-hidden="false"
                />
                <span className={styles.distanceLabel}>
                    {formatDistanceWithSuffix(props.store.distance)}
                </span>
            </div>
            <MapDirectionsLink
                addressParts={[
                    props.store.address,
                    props.store.address2,
                    props.store.city,
                    props.store.state,
                    props.store.postal,
                ]}
                className={styles.directionsLink}
            />
        </div>
    );
};

interface IOwnProps {}

interface IReduxProps {
    closestStore: IRetailStoreWithDistance | null;
    stores: IRetailStoreWithDistance[];
    selectedStoreID: ISyncStoreID | null;
}

interface IProps extends IOwnProps, IReduxProps {}

const CurrentStoreContainer = (props: IProps) => {
    const selectedStore = getSelectedStore(
        props.closestStore,
        props.selectedStoreID,
        props.stores,
    );
    if (!selectedStore) {
        return null;
    }
    return <CurrentStoreComponent store={selectedStore} />;
};

const mapStateToProps: TStateMapper<"retail", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    return {
        closestStore: myStoreSelector(rootState),
        stores: storeSelector(rootState),
        selectedStoreID: focusedStoreIDSelector(rootState),
        // Direct Props
        ...ownProps,
    };
};

export const CurrentStore = connect(mapStateToProps)(CurrentStoreContainer);
