import React from "react";
import { Image } from "tsi-common-react/src/common/Image";
import { UnifiPreQualAdHeader } from "./UnifiPreQualAdHeader";
import { UnifiPreQualAdButtons } from "./UnifiPreQualAdButtons";
import prequalBg from "../../img/checkout/prequal-bg.png";
import sleepoutfittersCc from "../../img/checkout/sleepoutfitters-cc.png";

interface IProps {
    termAPR: string;
    termMonths: number;
    termThreshold: string;
    promoPeriod: string;
    aprInfoID: string;
}

export const UnifiPreQualAd = (props: IProps) => (
    <section className="pre-qual-block">
        <div className="u-flex-container">
            <section>
                <Image
                    className="responsive-img"
                    src={prequalBg}
                    alt=""
                    lazy={false}
                />
                <Image
                    className="responsive-img"
                    src={sleepoutfittersCc}
                    alt={gettext("Sleep Outfitters Credit Card")}
                    lazy={false}
                />
            </section>
            <section className="pre-qual-block__content">
                <UnifiPreQualAdHeader {...props} />
                <UnifiPreQualAdButtons termThreshold={props.termThreshold} />
            </section>
        </div>
    </section>
);
