import React from "react";
import classNames from "classnames";
import SVG from "react-inlinesvg";
import Modal from "react-modal";
import cookies from "js-cookie";
import styles from "./TimedEmailModal.module.scss";
import { Form } from "../../../forms/Form";
import { FormSubmit } from "../../../forms/FormSubmit";
import { FormInput } from "../../../forms";
import { signupForEmailOffers } from "../../../api/user";
import { ITimedEmailContent } from "../../../models/timed-email.interfaces";
import RichText from "../../../common/RichText";
import iconXClose from "../../../../img/icons/x-close.svg";

interface IProps {
    title: string;
    emailContent: ITimedEmailContent;
    timing: string;
    onSubmit?: (height?: number) => void;
}

interface IState {
    isOpen: boolean;
    isSubmitting: boolean;
    formSubmitted: boolean;
    email: string;
    contentHeight: number;
}

enum CookieName {
    MODAL_SEEN = "timed_email_modal_seen",
    EMAIL_SUBMITTED = "timed_email_modal_submitted",
}
export class TimedEmailModal extends React.Component<IProps, IState> {
    private readonly MOBILE_WIDTH_THRESHOLD = 501;
    private contentElem: HTMLElement | undefined | null;

    public state: IState = {
        isOpen: true,
        isSubmitting: false,
        formSubmitted: false,
        email: "",
        contentHeight: 360,
    };

    componentDidMount(): void {
        const urls = this.props.emailContent.pages?.map((page) => page.url);
        const isCurrentPageIncluded = urls.includes(window.location.pathname);
        this.setState({
            isOpen: isCurrentPageIncluded,
        });
        if (!isCurrentPageIncluded) {
            return;
        }
        const startDate = new Date(this.props.timing);
        const isPastStartDate = new Date() >= startDate;
        this.setState({
            isOpen: isPastStartDate,
        });
        if (!isPastStartDate) {
            return;
        }
        const isEmailSubmitted = !!cookies.get(CookieName.EMAIL_SUBMITTED);
        this.setState({
            isOpen: !isEmailSubmitted,
        });
        if (isEmailSubmitted) {
            return;
        }
        const modalSeenExpireTime = cookies.get(CookieName.MODAL_SEEN);
        if (!modalSeenExpireTime) {
            return;
        }
        const shouldModalShow = new Date() >= new Date(modalSeenExpireTime);
        this.setState({
            isOpen: shouldModalShow,
        });
    }

    private readonly isMobileWidth = () => {
        return window.innerWidth < this.MOBILE_WIDTH_THRESHOLD;
    };

    private readonly onCloseModal = (
        event: React.MouseEvent<Element> | React.KeyboardEvent<Element>,
    ) => {
        if (event) {
            event.preventDefault();
        }
        const date = new Date();
        date.setTime(date.getTime() + 24 * 3600 * 1000);
        const expires = date.toUTCString();
        cookies.set(CookieName.MODAL_SEEN, expires);
        this.setState({
            isOpen: false,
        });
    };

    private readonly onSubmit = async (
        event: React.FormEvent<HTMLFormElement>,
    ) => {
        event.preventDefault();

        this.setState({
            isSubmitting: true,
        });

        try {
            await signupForEmailOffers(this.state.email);
            cookies.set(CookieName.EMAIL_SUBMITTED, new Date().toUTCString());
            this.setState(
                {
                    isSubmitting: false,
                    formSubmitted: true,
                },
                () => {
                    if (this.props.onSubmit && this.contentElem) {
                        // If submit function exists, pass new height of content up to parent
                        this.props.onSubmit(
                            this.contentElem.getBoundingClientRect().height,
                        );
                    }
                },
            );
        } catch (error) {
            const data = error.response.body;
            console.log(data);
            this.setState({
                isSubmitting: false,
            });
        }
    };

    private readonly onEmailChange = async (
        event: React.FormEvent<HTMLInputElement>,
    ) => {
        const email = event.currentTarget.value;
        this.setState({
            email: email,
        });
    };

    private setContentHeight(height?: number) {
        let newHeight = 360;
        if (height) {
            newHeight = height;
        } else if (this.contentElem) {
            newHeight = this.contentElem.getBoundingClientRect().height;
        }

        this.setState({
            contentHeight: newHeight,
        });
    }

    private readonly onAfterModalOpen = (height?: number) => {
        this.setContentHeight(height);
    };

    private buildFootnotes() {
        const footnotesStyles = classNames({
            [styles.footnotes]: true,
            [styles.footnotesSubmitted]: this.state.formSubmitted,
            [this.props.emailContent.footnotes.alignment || ""]: true,
        });
        if (!this.props.emailContent.footnotes.content) {
            return;
        }
        return (
            <RichText
                className={footnotesStyles}
                html={this.props.emailContent.footnotes.content}
            />
        );
    }

    render() {
        const {
            content_text: contentText,
            close_text: closeText,
            confirm_content: confirmContent,
        } = this.props.emailContent;

        const style = {
            overlay: {
                backgroundColor: "rgba(0,0,0,0.2)",
            },
            content: {
                height: `${this.state.contentHeight + 2}px`,
            },
        };

        return (
            <Modal
                onAfterOpen={() => {
                    this.onAfterModalOpen(this.isMobileWidth() ? 600 : 700);
                }}
                contentLabel="Buy Direct For Exclusive Benefits"
                aria={{ modal: true }}
                isOpen={this.state.isOpen}
                onRequestClose={this.onCloseModal}
                role="dialog"
                overlayClassName={"timed-email-modal--overlay"}
                className={styles.root}
                style={style}
                shouldCloseOnOverlayClick={false}
            >
                <div>
                    <button
                        className={`${styles.close} email-modal__close`}
                        onClick={this.onCloseModal}
                        aria-label={gettext("close")}
                    >
                        <SVG
                            aria-hidden={true}
                            className={styles.closeIcon}
                            src={iconXClose}
                            title={gettext("Close Icon")}
                        />
                    </button>
                    <div
                        ref={(ref) => {
                            this.contentElem = ref;
                        }}
                    >
                        {!this.state.formSubmitted && contentText.content && (
                            <RichText
                                className={`${styles.contentText} ${contentText.alignment} ${contentText.spacing.top_spacing}`}
                                html={contentText.content}
                            />
                        )}
                        {!this.state.formSubmitted && (
                            <div className={styles.form}>
                                <Form onSubmit={this.onSubmit}>
                                    <FormInput
                                        id="timed_email_modal_email"
                                        name="timed_email_modal_email"
                                        type="email"
                                        placeholder={gettext(
                                            "Enter email address",
                                        )}
                                        required={true}
                                        autoComplete="email"
                                        value={this.state.email}
                                        onChange={this.onEmailChange}
                                        disabled={this.state.isSubmitting}
                                    />
                                    <FormSubmit
                                        type="submit"
                                        value={gettext("JOIN")}
                                    />
                                </Form>
                            </div>
                        )}
                        {this.state.formSubmitted && confirmContent.content && (
                            <RichText
                                html={confirmContent.content}
                                className={`${confirmContent.alignment} ${confirmContent.spacing.top_spacing} ${confirmContent.spacing.bottom_spacing}`}
                            />
                        )}
                    </div>
                    {!this.state.formSubmitted && this.buildFootnotes()}
                    {!this.state.formSubmitted && (
                        <p
                            className={styles.closeText}
                            dangerouslySetInnerHTML={{ __html: closeText }}
                            onClick={this.onCloseModal}
                        />
                    )}
                </div>
            </Modal>
        );
    }
}
